@import "../../../App"

.hero
  background-color: var(--color-primary)
  color: var(--color-bg)
  height: 100dvh
  display: flex
  align-items: center
  justify-content: center
  position: relative
  overflow: hidden

  .hero-content
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin-bottom: 128px
    z-index: 1

    .social-content
      position: relative
      width: 100%
      display: flex
      justify-content: flex-end
      z-index: 21
      padding: 0 max(1.6vw, 8px)

      .hero-socials
        color: var(--color-primary-900)
        margin-bottom: 16px

        &:hover
          color: var(--color-accent)

    .mega-text
      position: relative
      font-size: 8.4vw
      font-weight: 700
      line-height: 0.7
      z-index: 10
      color: var(--color-text)
      filter: drop-shadow(0.05em 0.05em var(--color-accent))

      @include media("<=tablet")
        font-size: 14.4vw
        text-align: center

    .sub-content
      width: 100%
      padding: 0 max(1.6vw, 8px)
      display: flex
      justify-content: space-between

      .content-left
        max-width: max(30vw, 120px)

        .sub-text
          font-size: max(1.6vw, 1rem)
          color: var(--color-primary-900)

      .content-right
        justify-content: flex-end
        max-width: max(30vw, 120px)

        .transition-text
          font-size: max(3.2vw, 1.6rem)
          transition: 0.3s all
          text-align: right
          color: var(--color-primary-900)

    .mega-image
      height: min(90vh, 120vw)
      position: absolute
      bottom: 0
      overflow: none
      z-index: 20

  .blob
    width: 24vw
    height: 24vw
    position: absolute
    transition: 8s all
    filter: blur(18vw)
    background-color: var(--color-accent)

  .blob-1
    border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%

  .blob-2
    border-radius: 30% 50% 80% 20% / 20% 70% 30% 20%

  .flair
    position: absolute

  .coords
    bottom: 0
    left: 0
    margin: max(1.6vw, 8px)
    font-size: max(2.8vw, 1.8rem)
    width: max(32vw, 250px)
    color: var(--color-primary-300)
    z-index: 1

  .planets
    position: absolute
    opacity: 0.2
    z-index: 0

    &-1
      bottom: -500px
      left: -400px
      animation: planet-movement 20s infinite linear

    &-2
      width: max(30vw, 420px)
      top: -320px
      right: 0
      animation: planet-movement 10s infinite linear

  .hero-marquee
    color: var(--color-primary-700)
    z-index: 1

@keyframes planet-movement
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)
