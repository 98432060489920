@import "../../../App.sass"

.project-page
  min-height: 100vh
  padding: var(--padding-hpage)
  display: flex
  overflow: hidden

  .project-page-container
    flex: 1
    display: flex
    flex-direction: column
    justify-content: flex-end
    align-items: center
    padding: var(--padding-hpage)
    overflow: hidden
    gap: 1.2rem

    .project-image-container
      width: 100%
      flex: 1
      display: flex
      justify-content: center
      align-items: center

    .project-text-container
      width: 100%
      display: flex
      align-items: flex-end
      gap: 0.8rem
      color: var(--white-500)

      @include media("<=tablet")
        flex-direction: column
        align-items: flex-start

      .project-index
        font-size: 8vw

        @include media("<=tablet")
          display: none

      .project-title-container
        display: flex
        flex-direction: column
        gap: 0.8rem
        max-width: 40%

        @include media("<=tablet")
          max-width: 100%

        .project-title
          font-size: var(--font-size-title)

        .project-tech-container
          display: flex
          flex-wrap: wrap
          gap: 0.2rem
          color: var(--color-text)

        .project-glance
          font-size: var(--font-size-text)

      .project-description-container
        display: flex
        flex-direction: column
        gap: 0.8rem
        max-width: 50%
        position: relative

        @include media("<=tablet")
          max-width: 100%

        .project-description
          font-size: var(--font-size-text)

        .project-links-container
          position: absolute
          top: -3.2rem
          right: 0
          display: flex
          align-items: flex-end
          gap: 1rem

          @include media("<=tablet")
            position: relative
            top: 0
            right: 0
