.socials
  display: flex
  margin: 4px 0px

  &-sm
    gap: 16px

  &-md
    gap: 16px

  &-lg
    gap: 2rem

  .item
    transition: 0.3s all

    &:hover
      cursor: pointer
      scale: 1.05

  .sm
    font-size: max(1.6vw, 1.6rem)

  .md
    font-size: max(3.2vw, 2.8rem)

  .lg
    font-size: max(4.8vw, 3.2rem)
