@import "../../constants/Theme"

.colour-wipe
    background-color: var(--color-accent)
    color: var(--color-bg)
    position: fixed
    width: 100%
    height: 100vh
    overflow: hidden
    z-index: 999
    display: flex
    justify-content: center
    align-items: center
