@import "../../../constants/Theme"
@import "../../../App.sass"

.spotlight
  background-image: linear-gradient(to bottom, var(--color-primary), var(--color-primary-100))
  padding: var(--padding-vpage) var(--padding-hpage)
  color: var(--color-text)
  display: flex
  flex-direction: column
  gap: 8px
  min-height: 100vh
  position: relative

  .sl-color-dot
    bottom: var(--padding-hpage)
    right: var(--padding-hpage)

  .projects
    display: flex
    flex-direction: column
    gap: 16px
    margin-bottom: 48px

    .project
      display: flex
      align-items: center
      justify-content: flex-start
      gap: 16px
      padding: 32px 0px
      border-top: 1px solid var(--color-text-100)
      text-decoration: none
      color: var(--color-text)

      &:last-child
        border-bottom: 1px solid var(--color-text-100)

      @include media("<=tablet")
        flex-direction: column
        align-items: flex-start

      .sp-header
        display: flex
        justify-content: flex-start
        align-items: center
        flex: 2
        gap: 16px

        .sp-number
          font-size: var(--font-size-title)
          flex: 0.4
          display: flex
          align-items: flex-end

        .sp-image-container
          flex: 1
          opacity: 0
          transform: translateX(-8px)
          transition: 0.3s all
          display: flex
          align-items: center

          .sp-image
            width: 100%

          @include media("<=tablet")
            display: none

        .sp-name
          flex: 2
          font-size: var(--font-size-text)

      .sp-glance
        flex: 1
        font-size: var(--font-size-text)

      &:hover
        cursor: pointer

        .sp-image-container
          opacity: 1
          transform: translateX(0px)
