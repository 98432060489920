@import "../../../App.sass"

.about-me
  min-height: 100vh
  background-image: linear-gradient(to bottom, var(--color-primary), var(--color-bg))
  display: flex

  @include media("<=tablet")
    flex-direction: column

  .about-left-container
    flex: 1
    display: flex
    flex-direction: column

    @include media("<=tablet")
      flex: none
      height: 100vh
      gap: 32px

    .about-summary
      flex: 1
      display: flex
      align-items: flex-end
      padding: var(--padding-hpage)
      font-size: var(--font-size-text)

      @include media("<=tablet")
        align-items: flex-start

  .about-right-container
    flex: 1
    overflow: hidden
    height: 100vh

    @include media("<=tablet")
      flex: none
      overflow: visible
      margin-top: 2rem
      height: 100%

    .image-cover
      width: 100%
      height: 100%
      object-fit: cover

    .image-container-top
      height: 62vh
      display: flex

      @include media("<=tablet")
        height: auto

      .image-container-left
        flex: 1

      .image-container-right
        flex: 1

    .image-container-bottom
      height: 38vh

      @include media("<=tablet")
        height: auto
