.badge
  display: flex
  align-items: center
  justify-content: center
  background-color: var(--color-primary)
  gap: 0.4rem
  width: fit-content
  padding: 0.4rem 0.8rem
  border-radius: 0.4rem
  transition: 0.3s background-color, 0.3s color

  .badge-icon
    width: max(1.6vw, 1.2rem)

  .badge-text
    margin-top: 0.3rem
    font-size: max(1.4vw, 1rem)
    font-weight: 500
