.not-found
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  position: relative
  overflow: hidden

  .title
    font-size: max(8vw, 6rem)
    color: var(--color-accent)

  .subtitle
    font-size: max(4vw, 2rem)

  .content
    font-size: max(1.2vw, 1.6rem)
    max-width: min(90vw, 40rem)
    text-align: center
    margin: 16px 0px
